import './bootstrap';

import { createApp } from 'vue';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
import flatpickr from 'flatpickr';

window.Swal = Swal;

import PartnersList from './components/partners/list.vue';
import SegmentsList from './components/segments/list.vue';
import InventoryBidders from './components/inventory/bidders.vue';
import Select2Multiple from './components/select2/multiple.vue';
import BlockList from './components/blockList/list.vue';
import UsersList from './components/users/list.vue';
import BiddersOptionsTable from './components/bidders/options-table.vue';
import AdsTxtList from './components/ads.txt/list.vue';

const components = {
    'partners-list': PartnersList,
    'segments-list': SegmentsList,
    'inventory-bidders': InventoryBidders,
    'select2-multiple': Select2Multiple,
    'block-list': BlockList,
    'users-list': UsersList,
    'bidders-options-table': BiddersOptionsTable,
    'ads-txt-list': AdsTxtList,
};

let n = 0;
for (const tag in components) {
    for (const el of document.querySelectorAll(tag)) {
        const app = createApp({
            template: el.outerHTML,
            name: `${tag} ${++n}`,
        });
        app.component(tag, components[tag]);
        app.mount(el);
    }
}

// are we deep-linked to a tab?
let goToTabFromHash = function () {
    const hash = window.location.hash.split('#');
    const currentTab = document.querySelector('.nav-link[data-bs-target="#' + hash[1] + '"]');
    if (currentTab) {
        const bsTab = new bootstrap.Tab(currentTab);
        bsTab.show();
    }
};

if (window.location.hash) {
    goToTabFromHash();
}

let tabButtons = document.querySelectorAll('.nav-link[data-bs-target]');
if (tabButtons.length) {
    for (let tabButton of tabButtons) {
        tabButton.addEventListener('show.bs.tab', function (ev) {
            window.location.hash = ev.target.getAttribute('data-bs-target');
        });
    }
    window.onpopstate = goToTabFromHash;
}

import $ from 'jquery';
window.$ = $;

$('body').on('click', '.btn-delete-confirm', async function () {
    const route = $(this).attr('data-route');
    const confirm = await Swal.fire({
        title: 'Confirm Deletion',
        text: 'Are you sure you want to delete this?',
        showDenyButton: true,
        confirmButtonText: 'Delete',
        denyButtonText: 'Cancel',
        reverseButtons: true,
    }).then(async function(result) {
        if (! result.isConfirmed) {
            return;
        }

        try {
            await axios.delete(route);
            await Swal.fire('Successfully deleted', 'The item has been permanently deleted.', 'success');
            window.location.reload();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err.message,
            });
        }
    });
});

// In partners form, the user can only select either quantcast GDPR tag or Custom CMP, but they can't select both at the same time.
$(document).ready(function() {
    $('#opt_gdpr_quantcast').change(function() {
        if ($('#opt_gdpr_quantcast').is(':checked') === true) {
            $('#has_custom_cmp').removeAttr('checked');
            $('#has_custom_cmp').attr('disabled', true);
        } else {
            $('#has_custom_cmp').removeAttr('disabled');
        }
    });
});

$(document).ready(function() {
    $('#has_custom_cmp').change(function() {
        if ($('#has_custom_cmp').is(':checked') === true) {
            $('#opt_gdpr_quantcast').removeAttr('checked');
            $('#opt_gdpr_quantcast').attr('disabled', true);
        } else {
            $('#opt_gdpr_quantcast').removeAttr('checked');
            $('#opt_gdpr_quantcast').removeAttr('disabled');
        }
    });
});

// In the partners form, display elements only if 'Refresh on scroll' is checked.
$(document).ready(function() {
    if ($('#opt_refresh').is(':checked') === true) {
        $('#refresh_max_visibility').css('visibility', 'visible');
        $('#refresh_time_visibility').css('visibility', 'visible');
    }
    $('#opt_refresh').change(function() {
        if ($('#opt_refresh').is(':checked') === true) {
            $('#refresh_max_visibility').css('visibility', 'visible');
            $('#refresh_time_visibility').css('visibility', 'visible');
        } else {
            $('#opt_refresh').removeAttr('checked');
            $('#refresh_max').value = null;
            $('#refresh_time').value = null;
            $('#refresh_max_visibility').css('visibility', 'hidden');
            $('#refresh_time_visibility').css('visibility', 'hidden');
        }
    });
});

$(document).ready(function() {
    // when you load the page, the global script is enabled, so we need to trigger the change and disable it.
    // In the select2 a list gets created, so you can't actually add the attribute to the select 2. You have to add it to the parent's parent.
    $('#is_global').change(function() {
        if ($('#is_global').is(':checked')) {
            $('[name="selected_partners[]"]').parents('.col-lg-9.col-xl-12').attr('style', "display:none;");
            $('[name="selected_partners[]"]').val('').change();
            $('[name="excluded_partners[]"]').parents('.col-lg-9.col-xl-12').removeAttr('style');
        } else {
            $('[name="selected_partners[]"]').parents('.col-lg-9.col-xl-12').removeAttr('style');
            $('[name="excluded_partners[]"]').val('').change();
            $('[name="excluded_partners[]"]').parents('.col-lg-9.col-xl-12').attr('style', "display:none;");
        }
    })
    $('#is_global').trigger('change');
});

$(document).ready(function() {
    $(".script-form select[name='type'], .script-form input[name='type']").on('change', (ev) => {
        const url = $("#url-area");
        const snippet = $("#snippet-area");
        url.hide();
        snippet.hide();

        if (ev.target.value === 'snippet') {
            snippet.show();
        }
        if (ev.target.value === 'url') {
            url.show();
        }
    });
    $(".script-form select[name='type'], .script-form input[name='type']").trigger('change');
});

flatpickr('#expires_at', {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
});
